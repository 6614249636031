<template>
  <Header />
  <div class="page">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import { mapActions, mapState } from "vuex";

import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState({
      region: (state) => state.region.region,
      regionConfirm: (state) => state.api.regionConfirm,
    }),
    isHome() {
      return this.$route.name === "Home";
    },
  },
  methods: {
    ...mapActions([
      "fetchTariffs",
      "fetchRegion",
      "fetchNotifications",
      "fetchPromo",
      "fetchEsim",
      "fetchSub",
      "fetchFAQ",
    ]),
  },
  async created() {
    await this.fetchRegion();
    await this.fetchTariffs({
      siteId: this.region || this.regionConfirm.siteId,
    });
    await Promise.all([
      this.fetchPromo(),
      this.fetchEsim(),
      this.fetchSub(),
      this.fetchFAQ(),
    ]);
  },
};
</script>

<style lang="scss">
@import "swiper/css";
:root {
  // Шрифты
  --fontPrimary: "T2 Rooftop", sans-serif;
  --fontSecondary: "T2 Halvar Breit", "Trebuchet MS", Helvetica, sans-serif;

  // цвета
  --colorBlack: #000000;
  --colorWhite: #fff;
  --colorPink: #ff3495;
  --colorGreen: #a7fc00;
  --colorBlue: #0000ff;
  --colorGrey: #f2f3f5;
  --colorLightBlue: #00bfff;
  --colorPrimaryBg: var(--colorPink);
  --colorPrimaryText: var(--colorWhite);
  --colorSecondaryBg: var(--colorGreen);
  --colorSecondaryText: var(colorBlack);

  // высота хедера
  --heightHeader: 64px;

  // ширина контейнера
  --widthContainer: 1136px;
  // расстояние контейнера по краям
  --widthGutter: 8px;
  // расстояние между блоками по вертикали
  --widthGapV: 8px;
  // расстояние между блоками по горизонтали
  --widthGapH: 12px;
  // внутренний отступ в блоке
  --widthPadding: 12px;

  // размер текста
  --sizeHeading1: 32px;
  --sizeHeading2: 24px;
  --sizeHeading3: 22px;
  --sizeHeading4: 18px;
  --sizeHeading5: 16px;
  --sizeHeading6: 12px;
  --sizeText: 15px;
  --sizeButton: 16px;

  // Высота линий
  --lineHeightHeading1: 30px;
  --lineHeightHeading2: 22px;
  --lineHeightHeading3: 20px;
  --lineHeightHeading4: 16px;
  --lineHeightHeading5: 14px;
  --lineHeightHeading6: 10px;
  --lineHeightText: 22px;
  --lineHeightButton: 20px;

  // Радиус внутренний и внешний
  --radiusOuter: 16px;
  --radiusInner: 12px;
}

@include md {
  :root {
    --heightHeader: 80px;
    --widthGutter: 16px;
    --widthGapV: 16px;
    --widthGapH: 32px;
    --widthPadding: 24px;

    --sizeHeading1: 38px;
    --sizeHeading2: 28px;
    --sizeHeading3: 24px;
    --sizeHeading4: 20px;
    --sizeHeading5: 16px;
    --sizeHeading6: 12px;

    --lineHeightHeading1: 34px;
    --lineHeightHeading2: 24px;
    --lineHeightHeading3: 22px;
    --lineHeightHeading4: 18px;
    --lineHeightHeading5: 14px;
    --lineHeightHeading6: 10px;

    --radiusOuter: 32px;
    --radiusInner: 24px;
  }
}

*,
::after,
::before {
  box-sizing: border-box;
  outline-color: var(--colorBlack);
}

body {
  margin: 0;
  color: var(--colorBlack);
  background-color: var(--colorBlack);
  font-family: var(--fontPrimary);
  font-size: var(--sizeText);
  line-height: var(--lineHeightText);
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch;
}

.page {
  background: var(--colorWhite);
  border-radius: var(--radiusOuter);
  @include md {
    border-radius: var(--radiusOuter);
  }
}

input,
textarea {
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    text-decoration: none !important;
  }
}

#app {
  min-height: 100vh;
  display: grid;
  grid-template-rows: max-content auto max-content;
}

.modal-open {
  overflow: hidden;
}

.title,
h1,
h2,
h3,
h4,
h5,
h6,
strong,
b {
  font-weight: 800;
  font-family: var(--fontSecondary);
}

.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  margin: 0;
  span {
    text-transform: none;
  }
}

p {
  margin: 0;
}

h1 {
  font-size: var(--sizeHeading1);
  line-height: var(--lineHeightHeading1);
}
h2 {
  font-size: var(--sizeHeading2);
  line-height: var(--lineHeightHeading2);
}
h3 {
  font-size: var(--sizeHeading3);
  line-height: var(--lineHeightHeading3);
}
h4 {
  font-size: var(--sizeHeading4);
  line-height: var(--lineHeightHeading4);
}
h5 {
  font-size: var(--sizeHeading5);
  line-height: var(--lineHeightHeading5);
}
h6 {
  font-size: var(--sizeHeading6);
  line-height: var(--lineHeightHeading6);
}

.highlight {
  background-color: yellow !important;
}
mark {
  background: none !important;
}
</style>
